import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
//import { Subject, Subscription } from "https://dev.jspm.io/rxjs@6/_esm2015";
//import { filter, map } from "https://dev.jspm.io/rxjs@6/_esm2015/operators";
import microfrontendLayout from "./microfrontend-layout.html";
import '@angular/localize/init';

const data = {
  loaders: {
    cssloaders: `<div class="wrapper"><div class="overlay"><div class="spinner-wrapper"><div class="loading-screen-icon-2"><div class="loading-text-wrapper-6"><span class="loader-5"></span><p>PROCESSING</p></div></div></div></div></div>`
  },
  props: {

  }
};
const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  }
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();

